
export function required(message = '') {
    return (value) => {
        if (!value) {
            return message;
        }

        return '';
    };
}


export function email(message = '') {
    return (value) => {
        if (/@/.test(value)) {
            return '';
        }

        return message;
    };
}
