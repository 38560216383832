import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '../../components/button';

import Form from '../../components/forms/form';
import Input from '../../components/forms/input';
import FormButtons from '../../components/forms/form-buttons';
import { required } from '../../components/forms/field-validators';

import { newSession, getAccountInfo } from '../../api';
import { saveSession, setUserSession, setUser } from '../../actions/user';


const propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.objectOf(() => true).isRequired,
};


const LoginBody = styled.div`
    max-width: 600px;
    margin: 10px;
`;


function Login(props) {
    const formRef = useRef(null);

    function handleSession(sessionId) {
        const { dispatch, history } = props;

        saveSession(sessionId);
        dispatch(setUserSession(sessionId));

        getAccountInfo(sessionId).then((res) => {
            if (res.ok) {
                dispatch(setUser(res.data));
            }

            history.push('/');
        });
    }

    function onSubmit(values) {
        newSession(values).then((res) => {
            if (res.ok) {
                handleSession(res.data.session_id);
            } else if (formRef.current) {
                formRef.current.setErrors({
                    login: res.message || '',
                });
            }
        });
    }

    return (
        <LoginBody>
            <Form ref={formRef} onSubmit={onSubmit}>
                <Input
                    name='login'
                    label='Login'
                    validators={[
                        required('Please enter username'),
                    ]}
                />

                <Input
                    name='password'
                    label='Password'
                    type='password'
                    validators={[
                        required('Please enter password'),
                    ]}
                />

                <FormButtons>
                    <Button>Login</Button>
                </FormButtons>
            </Form>
        </LoginBody>
    );
}


function mapStateToProps() {
    return {};
}


Login.propTypes = propTypes;


export default connect(mapStateToProps)(Login);
