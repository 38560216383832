import {
    SET_ACCOUNT_TREE,
    ADD_ALBUM,
} from '../constants';


export function setAccountTree(accountTree) {
    return {
        type: SET_ACCOUNT_TREE,
        payload: {
            accountTree,
        },
    };
}


export function addAlbum(album, photos) {
    return {
        type: ADD_ALBUM,
        payload: {
            album,
            photos,
        },
    };
}
