import React from 'react';


export default function NotFound() {
    return (
        <div>
            404 Not Found
        </div>
    );
}
