import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FolderThumbnail from './folder-thumbnail';
import AlbumThumbnail from './album-thumbnail';


const propTypes = {
    folders: PropTypes.arrayOf(() => true).isRequired,
    albums: PropTypes.arrayOf(() => true).isRequired,
};

const defaultProps = {};


const Content = styled.div`
    display: flex;
    flex-wrap: wrap;

    margin: 10px 0 10px 0;
`;


function Dir(props) {
    function renderFolders(folders) {
        const ret = [];

        for (let i = 0; i < folders.length; i += 1) {
            const folder = folders[i];

            const f = (
                <FolderThumbnail
                    folder={folder}
                    url={folder.path}
                />
            );

            ret.push(f);
        }

        return ret;
    }

    function renderAlbums(albums) {
        const ret = [];

        for (let i = 0; i < albums.length; i += 1) {
            const album = albums[i];

            const al = (
                <AlbumThumbnail
                    album={album}
                    url={album.path}
                />
            );

            ret.push(al);
        }

        return ret;
    }

    if (props.folders.length === 0 && props.albums.length === 0) {
        return (
            <Content>
                Empty folder
            </Content>
        );
    }

    return (
        <Content>
            {renderFolders(props.folders)}
            {renderAlbums(props.albums)}
        </Content>
    );
}


Dir.propTypes = propTypes;
Dir.defaultProps = defaultProps;


export default Dir;
