import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf([
        'submit',
        'button',
    ]),
};

const defaultProps = {
    type: 'submit',
};


const ButtonBody = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    padding: 6px 25px 6px 25px;

    border: 1px solid #cccccc;
    outline: none;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    color: black;
    background-color: #e8e8e8;

    font-size: 16px;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        background-color: #d5d5d5;
    }
`;


function Button(props) {
    const { type, children } = props;

    return (
        <ButtonBody type={type}>
            {children}
        </ButtonBody>
    );
}


Button.propTypes = propTypes;
Button.defaultProps = defaultProps;


export default Button;
