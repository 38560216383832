import React from 'react';
import styled from 'styled-components';

import { mainColor } from '../themes';


const propTypes = {};

const defaultProps = {};


const FooterBody = styled.div`
    height: 30px;
    background-color: ${mainColor};
`;


function Footer() {
    return (
        <FooterBody />
    );
}


Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;


export default Footer;
