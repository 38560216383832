import axios from 'axios';
import qs from 'qs';


export async function get(url, params = {}) {
    try {
        const response = await axios.get(url, { params });
        return response.data;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return {};
    }
}


export function post(url, params = {}) {
    try {
        const response = axios.post(url, qs.stringify(params));
        return response.data;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return {};
    }
}
