import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from '../store';

import RootLayout from '../views/root-layout';


export default (
    <Provider store={store}>
        <Router>
            <Route path='/' component={RootLayout} />
        </Router>
    </Provider>
);
