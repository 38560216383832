import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mainColor } from '../themes';


const propTypes = {
    name: PropTypes.string.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    accountInfo: PropTypes.objectOf(() => true).isRequired,
    onLogout: PropTypes.func.isRequired,
};

const defaultProps = {};


const HeaderBody = styled.div`
    display: flex;
    background-color: ${mainColor};
`;


const HeaderAppName = styled.div`
    padding: 10px;
    color: #ffffff;
`;


const HeaderAuth = styled.nav`
    flex: 1 0 auto;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    > a {
        color: #ffffff;
        padding: 10px;
    }
`;


const UserAvatar = styled.img`
    width: 20px;
    height: 20px;
`;


function Header(props) {
    const {
        name,
        isUserLoaded,
        accountInfo,
        onLogout,
    } = props;

    function onUserLogout(evt) {
        evt.preventDefault();
        onLogout();
    }

    let authContent = null;

    if (isUserLoaded) {
        let avatar = null;

        if (accountInfo.avatar) {
            avatar = (
                <UserAvatar src={accountInfo.avatar.url} />
            );
        }

        authContent = (
            <>
                {avatar}
                <Link to='/' onClick={onUserLogout}>Logout</Link>
            </>
        );
    } else {
        authContent = (
            <>
                <Link to='/login'>Login</Link>
            </>
        );
    }

    return (
        <HeaderBody>
            <Link to='/'>
                <HeaderAppName>
                    {name}
                </HeaderAppName>
            </Link>

            <HeaderAuth>
                {authContent}
            </HeaderAuth>
        </HeaderBody>
    );
}


Header.propTypes = propTypes;
Header.defaultProps = defaultProps;


export default Header;
