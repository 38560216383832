import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        'text',
        'password',
    ]),
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    maxLength: PropTypes.number,
    validators: PropTypes.arrayOf(PropTypes.func),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};


const defaultProps = {
    type: 'text',
    defaultValue: '',
    maxLength: 255,
    validators: [],
    disabled: false,
    onChange: null,
};


const Field = styled.div`
    display: flex;
    flex-direction: column;

    margin: 0 0 10px 0;
    padding: 0;
`;


const FieldLabel = styled.label`
    font-weight: 600;
    display: block;
    padding: 3px 0 3px 0;
`;


const FieldInput = styled.input`
    padding: 5px;

    color: #333333;

    border: 1px solid #cccccc;
    border-radius: 4px;

    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;

    &:disabled {
        background-color: #f2f2f2;
        cursor: not-allowed;
    }
`;


const FieldError = styled.div`
    display: block;
    margin: 5px 0 0 0;
    color: #a94443;
`;


class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
            error: '',
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        const { value } = evt.target;

        this.setNewStateValue(value);
        this.validate(value);
    }

    setNewStateValue(value) {
        const { onChange } = this.props;

        if (onChange) {
            this.setState({ value }, () => {
                onChange(value);
            });
        } else {
            this.setState({ value });
        }
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) {
        this.setState({ value });
        this.validate(value);
    }

    setError(error) {
        this.setState({ error });
    }

    clearValue() {
        this.setState({
            value: this.props.defaultValue,
            error: '',
        });
    }

    validate(value) {
        const { validators } = this.props;

        for (let i = 0; i < validators.length; i += 1) {
            const error = validators[i](value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: '' });
        return true;
    }

    isValid() {
        return this.validate(this.getValue());
    }

    renderError() {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return (
            <FieldError>{error}</FieldError>
        );
    }

    render() {
        const {
            name,
            label,
            type,
            maxLength,
            disabled,
        } = this.props;

        const { value } = this.state;

        const input = (
            <FieldInput
                id={name}
                name={name}
                value={value}
                onChange={this.onChange}
                maxLength={maxLength}
                disabled={disabled}
                type={type}
            />
        );

        return (
            <Field>
                <FieldLabel htmlFor={name}>{label}</FieldLabel>
                {input}
                {this.renderError()}
            </Field>
        );
    }
}


Input.propTypes = propTypes;
Input.defaultProps = defaultProps;


export default Input;
