import theme from 'styled-theming';


export const mainColor = theme('mode', {
    main: '#5471b9',
    dark: '#000000',
});


export default {};
