import PropTypes from 'prop-types';
import React from 'react';


const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    className: PropTypes.string,
    disabledFields: PropTypes.bool,
};


const defaultProps = {
    className: '',
    disabledFields: false,
};


class Form extends React.Component {
    constructor(props) {
        super(props);

        this.fields = {};

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(evt) {
        evt.preventDefault();

        if (this.isValid()) {
            const values = this.getValues();
            this.props.onSubmit(values);
        }
    }

    getValues() {
        const values = {};

        Object.keys(this.fields).forEach((refName) => {
            const { current } = this.fields[refName];

            if (current && current.getValue) {
                values[refName] = current.getValue();
            }
        });

        return values;
    }

    setValues(values) {
        Object.keys(this.fields).forEach((refName) => {
            const { current } = this.fields[refName];

            if (refName in values && current && current.setValue) {
                const value = values[refName];
                current.setValue(value);
            }
        });
    }

    setErrors(errors) {
        Object.keys(this.fields).forEach((refName) => {
            const { current } = this.fields[refName];

            if (refName in errors && current && current.setError) {
                const error = errors[refName];
                current.setError(error);
            }
        });
    }

    clearValues() {
        Object.keys(this.fields).forEach((refName) => {
            const { current } = this.fields[refName];

            if (!current) {
                return;
            }

            if (current.setError) {
                current.setError('');
            }

            if (current.clearValue) {
                current.clearValue();
            }
        });
    }

    isValid() {
        let isValid = true;

        const refsNames = Object.keys(this.fields);

        for (let i = 0; i < refsNames.length; i += 1) {
            const refName = refsNames[i];
            const { current } = this.fields[refName];

            if (current && current.isValid && !current.isValid()) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    renderChildren(child) {
        const { disabledFields } = this.props;

        const cn = React.Children.map(child.props.children, (cd) => {
            if (!React.isValidElement(cd)) {
                return cd;
            }

            const { name } = cd.props;

            if (!name) {
                return this.renderChildren(cd);
            }

            if (this.fields[name] === undefined) {
                this.fields[name] = React.createRef();
            }

            let disabled = false;

            if (disabledFields || cd.props.disabled) {
                disabled = true;
            }

            return React.cloneElement(cd, {
                ref: this.fields[name],
                disabled,
            });
        });

        return React.cloneElement(child, {
            children: cn,
        });
    }

    render() {
        const classes = ['form'];

        const { className, disabledFields } = this.props;

        if (className) {
            classes.push(className);
        }

        const children = React.Children.map(this.props.children, (child) => {
            const { name } = child.props;

            if (!name) {
                return this.renderChildren(child);
            }

            if (this.fields[name] === undefined) {
                this.fields[name] = React.createRef();
            }

            let disabled = false;

            if (disabledFields || child.props.disabled) {
                disabled = true;
            }

            return React.cloneElement(child, {
                ref: this.fields[name],
                disabled,
            });
        });

        return (
            <form onSubmit={this.onSubmit} className={classes.join(' ')} noValidate>
                {children}
            </form>
        );
    }
}


Form.propTypes = propTypes;
Form.defaultProps = defaultProps;


export default Form;
