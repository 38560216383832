import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const propTypes = {
    children: PropTypes.element.isRequired,
};


const FormButtonsBody = styled.div`
    margin: 25px 0 0 0;
    padding: 0;

    button {
        margin-right: 10px;
    }

    button:last-child {
        margin-right: 0;
    }
`;


function FormButtons(props) {
    return (
        <FormButtonsBody>
            {props.children}
        </FormButtonsBody>
    );
}


FormButtons.propTypes = propTypes;


export default FormButtons;
