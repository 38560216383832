import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const propTypes = {
    album: PropTypes.objectOf(() => true).isRequired,
    url: PropTypes.string.isRequired,
};

const defaultProps = {};


const AlbumBody = styled.div`
    margin: 1px;
    padding: 0;

    width: 150px;
    height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid #cccccc;
    border-radius: 2px;

    cursor: pointer;
    color: #000000;

    &:hover {
        border-color: #333333;
        background-color: #fef37d;
    }
`;

const AlbumImg = styled.img`
    flex: 1 0 auto;

    width: 100px;
    max-height: 100px;
`;

const AlbumName = styled.div``;


function AlbumThumbnail(props) {
    const { album, url } = props;

    return (
        <Link to={url}>
            <AlbumBody>
                <AlbumImg src={album.cover_photo_url} alt={album.name} />
                <AlbumName>
                    {album.name}
                </AlbumName>
            </AlbumBody>
        </Link>
    );
}


AlbumThumbnail.propTypes = propTypes;
AlbumThumbnail.defaultProps = defaultProps;


export default AlbumThumbnail;
