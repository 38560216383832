import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import DirTree from './dir-tree';
import Dir from './dir';
import Album from './album';

import { getAlbum } from '../../api';
import { addAlbum } from '../../actions/albums';


const propTypes = {
    dispatch: PropTypes.func.isRequired,

    session: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    access: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,

    accountTree: PropTypes.objectOf(() => true).isRequired,
    privatePaths: PropTypes.objectOf(() => true).isRequired,
    publicPaths: PropTypes.objectOf(() => true).isRequired,
    albums: PropTypes.objectOf(() => true).isRequired,
};

const defaultProps = {};


const FileManagerBody = styled.div`
    display: flex;
    height: 100%;
`;


const ContentHeader = styled.div`
    margin: 10px 0 0 0;
    font-weight: 600;
`;


function FileManager(props) {
    const {
        dispatch,

        fullPath,
        username,
        access,
        path,

        session,

        accountTree,
        privatePaths,
        publicPaths,
        albums,
    } = props;

    function getPathContent() {
        let contentPath = '';

        if (path === undefined) {
            contentPath = access;
        } else {
            contentPath = `${access}/${path}`;
        }

        if (access === 'private') {
            return privatePaths[contentPath] || null;
        }

        if (access === 'public') {
            return publicPaths[contentPath] || null;
        }

        return null;
    }

    useEffect(() => {
        const pc = getPathContent();

        if (pc && pc.album_id_enc) {
            getAlbum(session, { albumId: pc.album_id_enc }).then((res) => {
                if (res.ok) {
                    dispatch(addAlbum(res.data.album, res.data.photos));
                }
            });
        }
    }, [fullPath]);

    function getFolders(folders, isPrivate = true) {
        const ret = [];

        let accessType = '';

        if (isPrivate) {
            accessType = 'private';
        } else {
            accessType = 'public';
        }

        for (let i = 0; i < folders.length; i += 1) {
            const folder = folders[i];

            let url = '';

            if (!fullPath || fullPath === '/') {
                url = `/albums/${username}/${accessType}/${folder.url_p}`;
            } else {
                url = `${fullPath}/${folder.url_p}`;
            }

            ret.push({
                ...folder,
                path: url,
            });
        }

        return ret;
    }

    function getAlbums(als, isPrivate = false) {
        const ret = [];

        let accessType = '';

        if (isPrivate) {
            accessType = 'private';
        } else {
            accessType = 'public';
        }

        for (let i = 0; i < als.length; i += 1) {
            const album = als[i];

            let url = '';

            if (!fullPath || fullPath === '/') {
                url = `/albums/${username}/${accessType}/${album.url_p}`;
            } else {
                url = `${fullPath}/${album.url_p}`;
            }

            ret.push({
                ...album,
                path: url,
            });
        }

        return ret;
    }

    let content = null;

    if (!path && !access) {
        content = (
            <>
                <ContentHeader>Private</ContentHeader>

                <Dir
                    folders={getFolders(accountTree.private.folders, true)}
                    albums={getAlbums(accountTree.private.albums, true)}
                />

                <ContentHeader>Public</ContentHeader>

                <Dir
                    folders={getFolders(accountTree.public.folders, true)}
                    albums={getAlbums(accountTree.public.albums, true)}
                />
            </>
        );
    } else {
        const pathContent = getPathContent();

        if (pathContent && pathContent.album_id_enc) {
            const album = albums[pathContent.album_id_enc] || null;

            content = (
                <Album
                    album={album}
                />
            );
        } else if (pathContent) {
            content = (
                <Dir
                    folders={getFolders(pathContent.folders, true)}
                    albums={getAlbums(pathContent.albums, true)}
                />
            );
        }
    }

    return (
        <FileManagerBody>
            <div>
                <DirTree
                    pathPrefix={`/albums/${username}`}
                    accountTree={accountTree}
                />
            </div>
            <div>
                {content}
            </div>
        </FileManagerBody>
    );
}


FileManager.propTypes = propTypes;
FileManager.defaultProps = defaultProps;


function mapStateToProps(state) {
    const { session } = state.user;

    const {
        isAccountTreeLoaded,
        accountTree,
        privatePaths,
        publicPaths,
        albums,
    } = state.albums;

    return {
        session,

        isAccountTreeLoaded,
        accountTree,
        privatePaths,
        publicPaths,
        albums,
    };
}


export default connect(mapStateToProps)(FileManager);
