import settings from './settings';
import { get } from './lib/net';


export function newSession(values) {
    return get(`${settings.apiUrl}/new_session`, values);
}


export function removeSession(session) {
    return get(`${settings.apiUrl}/remove_session`, {
        session_id: session,
    });
}


export function getAccountInfo(session) {
    return get(`${settings.apiUrl}/get_account_info`, {
        session_id: session,
    });
}


export function getAccountTree(session) {
    return get(`${settings.apiUrl}/get_account_tree`, {
        session_id: session,
    });
}


export function getAlbum(session, values) {
    return get(`${settings.apiUrl}/get_album_content`, {
        session_id: session,
        album_id_enc: values.albumId,
    });
}
