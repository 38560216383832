import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
} from 'redux';

import thunk from 'redux-thunk';

import user from './user';
import albums from './albums';


const reducers = combineReducers({
    user,
    albums,
});


const middlewares = applyMiddleware(thunk);


let store = null; // eslint-disable-line import/no-mutable-exports

if (!__REDUX_DEVTOOLS__) { // eslint-disable-line no-undef
    store = createStore(reducers, middlewares);
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle, max-len
    store = createStore(reducers, composeEnhancers(middlewares));
}

export default store;
