import PropTypes from 'prop-types';
import React from 'react';


const propTypes = {
    name: PropTypes.string.isRequired,
};

const defaultProps = {};


function FolderIcon(props) {
    const { name } = props;

    return (
        <img
            src='/static/img/folder.png'
            width='16'
            height='16'
            alt={name}
        />
    );
}


FolderIcon.propTypes = propTypes;
FolderIcon.defaultProps = defaultProps;


export default FolderIcon;
