import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';

import settings from '../settings';

import rootRoutes from '../routes/root';

import Header from '../components/header';
import Footer from '../components/footer';

import { removeSession, getAccountTree } from '../api';
import { clearSession, clearUserSession, clearUser } from '../actions/user';
import { setAccountTree } from '../actions/albums';


const propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.objectOf(() => true).isRequired,

    session: PropTypes.string.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    accountInfo: PropTypes.objectOf(() => true).isRequired,
};


const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;


const AppBody = styled.div`
    flex: 1 0 auto;
`;


function RootLayout(props) {
    const defaultMode = 'main';

    const {
        dispatch,
        history,
        session,
        isUserLoaded,
        accountInfo,
    } = props;

    useEffect(() => {
        if (isUserLoaded && session) {
            getAccountTree(session).then((res) => {
                if (res.ok) {
                    dispatch(setAccountTree(res.data.account_tree));
                }
            });
        }
    }, [isUserLoaded, session]);

    function onLogout() {
        removeSession(session);

        clearSession();

        dispatch(clearUserSession());
        dispatch(clearUser());

        history.push('/');
    }

    return (
        <ThemeProvider theme={{ mode: defaultMode }}>
            <AppContainer>
                <Header
                    name={settings.appName}
                    isUserLoaded={isUserLoaded}
                    accountInfo={accountInfo}
                    onLogout={onLogout}
                />

                <AppBody>
                    {rootRoutes}
                </AppBody>

                <Footer />
            </AppContainer>
        </ThemeProvider>
    );
}


function mapStateToProps(state) {
    const { session, isUserLoaded, accountInfo } = state.user;

    return {
        session,
        isUserLoaded,
        accountInfo,
    };
}


RootLayout.propTypes = propTypes;


export default connect(mapStateToProps)(RootLayout);
