import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import FolderIcon from './folder-icon';


const propTypes = {
    pathPrefix: PropTypes.string.isRequired,
    accountTree: PropTypes.objectOf(() => true).isRequired,
};

const defaultProps = {};


const TreeBody = styled.nav`
    height: 100%;
    min-width: 250px;

    margin: 0 15px 0 0;
    background-color: #e5e5e5;
    border-right: 1px solid #cccccc;
`;

const BranchBody = styled.div`
    display: flex;
    align-items: center;

    line-height: 25px;
    font-size: 20px;
`;

const TreeSubFolders = styled.div`
    padding: 0 0 0 10px;
    background-color: #f6f6f6;
`;

const FolderLink = styled(Link)`
    padding: 4px;

    flex-grow: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const FolderName = styled.div`
    flex: 1 0 auto;
`;

const FolderToggle = styled.div`
    position: relative;

    width: 33px;
    height: 33px;

    cursor: pointer;

    transition: 200ms;

    &:hover {
        background-color: #ffffff;
    }

    ::before {
        position: absolute;
        top: 12px;
        left: 10px;

        content: '';
        width: 0;
        height: 0;
    }
`;

const FolderDownToggle = styled(FolderToggle)`
    ::before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 9px solid #000000;
    }
`;

const FolderUpToggle = styled(FolderToggle)`
    ::before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid #000000;
    }
`;


function DirTree(props) {
    const { pathPrefix, accountTree } = props;

    const [visiblePaths, setVisiblePaths] = useState({
        [`${pathPrefix}/private`]: true,
        [`${pathPrefix}/public`]: true,
    });

    function togglePath(folderPath) {
        visiblePaths[folderPath] = !visiblePaths[folderPath];
        setVisiblePaths({ ...visiblePaths });
    }

    function renderTree(path, folder) {
        const folderPath = `${path}/${folder.url_p}`;

        let toggle = null;

        if (folder.folders.length > 0) {
            const ToggleComponent = visiblePaths[folderPath] ? FolderUpToggle : FolderDownToggle;
            toggle = (
                <ToggleComponent
                    onClick={() => { togglePath(folderPath); }}
                    onKeyPress={() => { togglePath(folderPath); }}
                    tabIndex='-1'
                    role='button'
                />
            );
        }

        let subfolders = null;

        if (visiblePaths[folderPath]) {
            const subfs = [];

            for (let i = 0; i < folder.folders.length; i += 1) {
                const f = folder.folders[i];
                subfs.push(renderTree(folderPath, f));
            }

            subfolders = (
                <TreeSubFolders>
                    {subfs}
                </TreeSubFolders>
            );
        }

        return (
            <>
                <BranchBody>
                    <FolderLink to={folderPath}>
                        <FolderIcon name={folder.folder_name} />
                        <FolderName>{folder.folder_name}</FolderName>
                    </FolderLink>
                    {toggle}
                </BranchBody>
                {subfolders}
            </>
        );
    }

    return (
        <TreeBody>
            <BranchBody>
                <FolderLink to='/'>
                    <FolderIcon name='Home' />
                    <FolderName>Home</FolderName>
                </FolderLink>
            </BranchBody>

            {renderTree(pathPrefix, accountTree.private)}
            {renderTree(pathPrefix, accountTree.public)}
        </TreeBody>
    );
}


DirTree.propTypes = propTypes;
DirTree.defaultProps = defaultProps;


export default DirTree;
