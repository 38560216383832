import React from 'react';
import styled, { keyframes } from 'styled-components';


const LoaderBarKeyframes = keyframes`
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
    }
`;


const LoaderBar = styled.div`
    display: flex;
    flex-direction: row;

    width: 120px;
    height: 60px;

    margin: 0;
    padding: 10px;

    cursor: progress;
`;


const LoaderBarRect = styled.div`
    margin: 2px;
    padding: 0;
    background-color: #A8989B;
    height: 100%;
    width: 6px;
    display: inline-block;

    animation: ${LoaderBarKeyframes} ${(props) => props.delay} 1.2s infinite ease-in-out;
`;


export default function RequestLoader() {
    return (
        <LoaderBar>
            <LoaderBarRect delay='-1.2s' />
            <LoaderBarRect delay='-1.1s' />
            <LoaderBarRect delay='-1.0s' />
            <LoaderBarRect delay='-0.9s' />
            <LoaderBarRect delay='-0.8s' />
            <LoaderBarRect delay='-0.7s' />
            <LoaderBarRect delay='-0.6s' />
            <LoaderBarRect delay='-0.5s' />
            <LoaderBarRect delay='-0.4s' />
        </LoaderBar>
    );
}
