import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import RequestLoader from '../components/request-loader';
import FileManager from '../components/file-manager/file-manager';


const propTypes = {
    match: PropTypes.objectOf(() => true).isRequired,

    isUserLoaded: PropTypes.bool.isRequired,
    accountInfo: PropTypes.objectOf(() => true).isRequired,

    isAccountTreeLoaded: PropTypes.bool.isRequired,
};


const LoaderBody = styled.div`
    display: flex;
    justify-content: center;
`;

const IndexBody = styled.div`
    height: 100%;
`;


function Index(props) {
    const {
        isUserLoaded,
        accountInfo,

        isAccountTreeLoaded,
    } = props;

    if (!isUserLoaded) {
        return null;
    }

    if (!isAccountTreeLoaded) {
        return (
            <LoaderBody>
                <RequestLoader />
            </LoaderBody>
        );
    }

    const { login } = accountInfo;

    const fullPath = props.match.url;

    const {
        username,
        access,
        0: path,
    } = props.match.params;

    return (
        <IndexBody>
            <FileManager
                fullPath={fullPath}
                username={username || login}
                access={access}
                path={path}
            />
        </IndexBody>
    );
}


function mapStateToProps(state) {
    const { session, isUserLoaded, accountInfo } = state.user;
    const { isAccountTreeLoaded } = state.albums;

    return {
        session,
        isUserLoaded,
        accountInfo,

        isAccountTreeLoaded,
    };
}


Index.propTypes = propTypes;


export default connect(mapStateToProps)(Index);
