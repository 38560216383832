import React from 'react';
import ReactDOM from 'react-dom';

import Settings from './settings';
import routes from './routes';
import store from './store';
import { loadSession, setUserSession, setUser } from './actions/user';
import { getAccountInfo } from './api';


function setTitle() {
    const els = document.getElementsByTagName('title');

    if (els.length > 0) {
        els[0].textContent = Settings.appName;
    }
}


function checkAuth(callback) {
    const sessionId = loadSession();

    if (!sessionId) {
        callback();
        return;
    }

    getAccountInfo(sessionId).then((res) => {
        if (res.ok) {
            store.dispatch(setUserSession(sessionId));
            store.dispatch(setUser(res.data));
        }

        callback();
    });
}


function hideAppLoader() {
    const els = document.getElementsByClassName('app-loader');

    if (els.length > 0) {
        els[0].hidden = true;
    }
}


function render() {
    const rootElement = document.getElementById('app');

    if (!__HOT_LOADER__) { // eslint-disable-line no-undef
        ReactDOM.render(routes, rootElement, () => {
            hideAppLoader();
        });
    } else {
        const HotRoutes = require('./hot/routes').default; // eslint-disable-line global-require
        ReactDOM.render(<HotRoutes />, rootElement, () => {
            hideAppLoader();
        });
    }
}


function main() {
    setTitle();

    checkAuth(() => {
        render();
    });
}


main();
