import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Index from '../views/index';

import Login from '../views/user/login';

import NotFound from '../views/not-found';


export default (
    <Switch>
        <Route exact path='/' component={Index} />
        <Route exact path='/albums/:username/:access/(.*)' component={Index} />
        <Route exact path='/albums/:username/:access' component={Index} />

        <Route exact path='/login' component={Login} />

        <Route component={NotFound} />
    </Switch>
);
