import {
    SET_USER_SESSION,
    CLEAR_USER_SESSION,
    SET_USER,
    CLEAR_USER,
} from '../constants';


export function saveSession(session) {
    if (window.localStorage) {
        localStorage.setItem('session', session);
    }
}


export function loadSession() {
    if (window.localStorage) {
        return localStorage.getItem('session');
    }

    return '';
}


export function clearSession() {
    if (window.localStorage) {
        localStorage.removeItem('session');
    }
}


export function setUserSession(session) {
    return {
        type: SET_USER_SESSION,
        payload: {
            session,
        },
    };
}


export function clearUserSession() {
    return {
        type: CLEAR_USER_SESSION,
    };
}


export function setUser(userInfo) {
    return {
        type: SET_USER,
        payload: {
            userInfo,
        },
    };
}


export function clearUser() {
    return {
        type: CLEAR_USER,
    };
}
