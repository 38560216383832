import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const propTypes = {
    folder: PropTypes.objectOf(() => true).isRequired,
    url: PropTypes.string.isRequired,
};

const defaultProps = {};


const FolderBody = styled.div`
    margin: 0 3px 3px 0;
    padding: 0;

    width: 150px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #cccccc;
    border-radius: 2px;

    cursor: pointer;
    color: #000000;
    background-color: #fef69f;

    &:hover {
        border-color: #333333;
        background-color: #fef37d;
    }
`;


function FolderThumbnail(props) {
    const {
        folder,
        url,
    } = props;

    return (
        <Link to={url}>
            <FolderBody>
                <div>
                    {folder.folder_name}
                </div>
            </FolderBody>
        </Link>
    );
}


FolderThumbnail.propTypes = propTypes;
FolderThumbnail.defaultProps = defaultProps;


export default FolderThumbnail;
