import {
    SET_ACCOUNT_TREE,
    ADD_ALBUM,
} from '../constants';


const initialState = {
    isAccountTreeLoaded: false,
    accountTree: {},

    albums: {},
};


function flatTree(path, folder) {
    let paths = {};

    let folderPath = '';

    if (path) {
        folderPath = `${path}/${folder.url_p}`;
    } else {
        folderPath = folder.url_p;
    }

    paths[folderPath] = folder;

    for (let i = 0; i < folder.folders.length; i += 1) {
        const f = folder.folders[i];
        const fPath = `${folderPath}/${f.url_p}`;

        paths[fPath] = { ...f };

        for (let j = 0; j < f.folders.length; j += 1) {
            paths = {
                ...paths,
                ...flatTree(fPath, f.folders[j]),
            };
        }

        for (let j = 0; j < f.albums.length; j += 1) {
            const a = f.albums[j];
            const aPath = `${fPath}/${a.url_p}`;
            paths[aPath] = { ...a };
        }
    }

    for (let i = 0; i < folder.albums.length; i += 1) {
        const a = folder.albums[i];

        let aPath = '';

        if (folderPath) {
            aPath = `${folderPath}/${a.url_p}`;
        } else {
            aPath = a.url_p;
        }

        paths[aPath] = { ...a };
    }

    return paths;
}


export default function userStore(state, action) {
    if (action.type === SET_ACCOUNT_TREE) {
        const { accountTree } = action.payload;

        const privatePaths = flatTree('', accountTree.private);
        const publicPaths = flatTree('', accountTree.public);

        return {
            ...state,
            isAccountTreeLoaded: true,
            accountTree,
            privatePaths,
            publicPaths,
        };
    }

    if (action.type === ADD_ALBUM) {
        const albums = { ...state.albums };
        const { album, photos } = action.payload;

        albums[album.id] = {
            ...album,
            photos,
        };

        return {
            ...state,
            albums,
        };
    }

    return state || initialState;
}
