import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const propTypes = {
    album: PropTypes.objectOf(() => true).isRequired,
};

const defaultProps = {};


const AlbumName = styled.div`
    margin: 10px 0 10px 0;
    font-weight: 600;
`;

const Photos = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PhotoContainer = styled.div`
    margin: 0 3px 3px 0;
    padding: 0;

    width: 150px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #cccccc;
    border-radius: 2px;

    cursor: pointer;
    color: #000000;
    background-color: #eee;

    &:hover {
        border-color: #333333;
        background-color: #ccc;
    }
`;

const Photo = styled.img`
    max-width: 140px;
    max-height: 140px;
`;


function Album(props) {
    const { album } = props;

    function renderPhotos() {
        const ret = [];

        for (let i = 0; i < album.photos.length; i += 1) {
            const photo = album.photos[i];

            const p = (
                <PhotoContainer>
                    <Photo src={photo.thumbnail_url} alt={photo.description} />
                </PhotoContainer>
            );

            ret.push(p);
        }

        return ret;
    }

    if (!album) {
        return null;
    }

    return (
        <div>
            <AlbumName>
                {album.album_name}
            </AlbumName>

            <div>
                {album.album_description}
            </div>

            <Photos>
                {renderPhotos()}
            </Photos>
        </div>
    );
}


Album.propTypes = propTypes;
Album.defaultProps = defaultProps;


export default Album;
